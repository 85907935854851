import React, { Component } from 'react';
import styled, { css } from 'styled-components';
import { reduxForm, Field } from 'redux-form';
import _ from 'lodash';
import { Link } from 'react-router-dom';
import Tooltip from 'rc-tooltip';
import 'react-phone-number-input/style.css';
import 'common/styles/tooltip.css';

import Spinner from 'common/Spinner';
import { registerFields, notiSwitches } from 'common/forms/formFields';
import renderField from 'common/forms/renderField';
import renderCheckbox from 'common/forms/renderCheckbox';
import LongButton from 'common/buttons/longButton.js';
import ErrorMessage from 'common/Error';
import 'react-phone-number-input/style.css';
import PhoneInput from 'react-phone-number-input';
import TimezoneDropdown from 'common/TimezoneInput';

import colors from 'utils/colors';
import { baseFont } from 'utils/fonts';
import { mobile } from 'utils/media';
import { registerValidate as validate } from 'utils/validations';

const SwitchWrapper = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  margin: 25px 0 25px;
  ${mobile(css`
    flex-direction: column;
    justify-content: space-between;
    align-items: left;
    margin-bottom: 0px;
  `)};
`;

const Loader = styled(Spinner)`
  margin: 0 auto;
`;

const FormWrapper = styled.form`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const Row = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  ${mobile(css`
    flex-direction: column;
  `)};
`;

const Helper = styled(Link)`
  text-decoration: underline;
  color: ${colors.base_text2};
  font-size: 16px;
  font-weight: 300;
  line-height: 22px;
  text-align: center;
  &:hover,
  &:focus {
    opacity: 0.5;
  }
  &:active {
    transform: scale(0.95);
    opacity: 0.5;
  }
  &:visited {
    color: ${colors.base_text2};
  }
`;

const SendButton = styled(LongButton)`
  margin-bottom: 25px;
  font-size: 16px;
  font-weight: bold;
  line-height: 21px;
  height: 55px;
  width: 225px;
  overflow: hidden;
`;

const Overlay = styled.h1`
  min-height: 32px;
  height: auto;
  width: 215px;
  color: #ffffff;
  font-family: ${baseFont};
  font-size: 13px;
  font-weight: bold;
  line-height: 16px;
  text-align: center;
`;

const customPhoneInput = styled.div`
  width: 275px;
  img {
    border: none;
    height: 14px;
    width: 27px;
  }
  button {
    border: none;
  }
  input {
    background: transparent;
    height: 60px
    width: 100%;
    color: ${colors.base_text3};
    font-size: 16px;
    border-style: none
    ::placeholder {
      font-size: 16px;
      color: ${colors.primary_gray};
      opacity: 0.75;
      transition: opacity 150ms ease-in-out;
    }
    ${(p) =>
      p.formtype !== 'register'
        ? mobile(
            css`
              padding-left: 5px;
              text-align: left;
            `
          )
        : null};
    + span {
      width: 80%;
      &:after {
        margin-top: -1.5px;
        z-index: 999;
        display: block;
        content: '';
        border-bottom: 2px solid
          ${(p) =>
            p.formtype === 'landing' || p.formtype === 'register'
              ? colors.base_text3
              : colors.base_text3};
        transform: scaleX(0);
        transition: transform 250ms ease-in-out;
      }
    }
  }
  input:focus {
    outline: none;
    + span {
      &:after {
        transform: scaleX(1.01);
      }
    }
    &::placeholder {
      opacity: 0.75;
    }
  }
  &::placeholder {
    font-size: 16px;
    color: ${colors.primary_gray};
    opacity: 0.75;
    transition: opacity 150ms ease-in-out;
  }
`;

const selectNotifString =
  'Select at least one notification option to never miss a package. If no notifications are selected, you can still view your package logs in your dashboard.';

class RegisterForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      time_zone: 'Select Timezone...',
    };
  }

  componentDidMount() {
    // Same as other comment. Leaving this here since it might be relevant to something we were working on before
    // Legacy code from other devs
    // add underline animation for phone number inputComponent
    // const input = document.querySelector('.rrui__input-field');
    // const parent = input.parentNode;
    // const wrapper = document.createElement('div');
    // wrapper.style.setProperty('width', '100%', 'important');
    // parent.replaceChild(wrapper, input);
    // wrapper.appendChild(input);
    // wrapper.appendChild(document.createElement('span'));
  }

  renderFields(showName) {
    return _.map(registerFields, (field, index) => {
      // if the field returned is an array (firstName, lastName)
      if (field.length) {
        if (!showName) return undefined;
        const row = field;
        return (
          <Row key={index}>
            {_.map(row, (fieldInRow, index) => {
              return (
                <Field
                  key={index}
                  component={renderField}
                  type={fieldInRow.type}
                  label={fieldInRow.label}
                  name={fieldInRow.name}
                  placeholder={fieldInRow.placeholder}
                  formtype="register"
                  row="true"
                />
              );
            })}
          </Row>
        );
      } else {
        return (
          <Field
            component={renderField}
            key={field.name}
            type={field.type}
            label={field.label}
            name={field.name}
            placeholder={field.placeholder}
            formtype="register"
          />
        );
      }
    });
  }

  renderCheckboxes() {
    return _.map(notiSwitches, ({ label, name, type }) => (
      <Field
        component={renderCheckbox}
        key={name}
        type={type}
        label={label}
        name={name}
        formtype="register"
        defaultValue={name === 'email_notification' ? true : false}
      />
    ));
  }

  render() {
    const { err, loading, showNotifText, showName = true } = this.props;
    return (
      <FormWrapper
        onSubmit={this.props.handleSubmit(this.props.onRegisterSubmit)}
        autoComplete="off"
      >
        {this.renderFields(showName)}
        <Row>
          <Tooltip
            placement="bottom"
            trigger={['hover']}
            prefixCls="rc-tooltip-phone"
            overlay={
              <Overlay style={{ color: colors.white }}>
                Phone number is required to pick up packages
              </Overlay>
            }
            style={{ marginTop: '50px' }}
          >
            <PhoneInput
              formtype="register"
              country="US"
              placeholder="Phone Number"
              onChange={(value) => this.props.change('phone', value)}
              style={{ marginTop: '25px' }}
              containerComponent={customPhoneInput}
            />
          </Tooltip>
        </Row>
        <Row>
          <TimezoneDropdown
            formtype="register"
            placeholder="Timezone"
            value={this.state.time_zone}
            onChange={(e) => {
              this.setState({ time_zone: e.target.id });
              this.props.change('time_zone', e.target.id);
            }}
            style={{ paddingBottom: '10px' }}
          />
        </Row>
        <SwitchWrapper>{this.renderCheckboxes()}</SwitchWrapper>
        {showNotifText && (
          <p style={{ color: '#ACACAC', fontSize: '.9rem', width: '75%' }}>
            {selectNotifString}
          </p>
        )}
        {err ? (
          <ErrorMessage style={{ margin: '0 auto 25px auto' }}>
            {err.message}
          </ErrorMessage>
        ) : null}
        <SendButton
          textColor="white"
          backgroundColor="base_text2"
          type="submit"
          className="sendBtn"
          style={{ overflow: 'hidden' }}
        >
          {loading ? <Loader /> : 'SUBMIT'}
        </SendButton>
        <Helper to="/dashboard/login">Have an account?</Helper>
      </FormWrapper>
    );
  }
}

export default reduxForm({
  validate,
  form: 'registerForm',
  touchOnBlur: false,
})(RegisterForm);
