import styled, { css } from 'styled-components';
import colors from 'utils/colors';
import { mobile, belowfour } from 'utils/media';

export default styled.div`
  width: 100%;
  height: auto;
  border: 1px #dddddd solid;
  border-radius: 4px;
  box-sizing: border-box;
  padding: 15px;
  display: flex;
  flex-direction: column;
  align-items: center;
  h1 {
    position: absolute;
    left: 50px;
    align-self: flex-start;
    cursor: default;
    user-select: none;
    text-align: left;
    margin-top: -25px;
    height: 20px;
    line-height: 20px;
    font-size: 20px;
    padding: 0 1px;
    ${belowfour(css`
      left: 0;
      right: 0;
      text-align: center;
    `)};
  }
  h1 span {
    padding: 0 10px;
    background-color: white;
    ${belowfour(css`
      padding: 0 5px;
    `)};
  }
  div {
    ${mobile(css`
      width: 100%;
    `)};
  }
`;
