import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Notifications from 'react-notification-system-redux';

class NotificationSystem extends Notifications {
  constructor(props) {
    super(props);
    this.state = { style: this.props.style };
  }
  componentDidMount() {
    this.refs.notify._getStyles.container = function (position) {
      return this.state.style.Containers.DefaultStyle;
    }.bind(this);
  }
}

class Notification extends React.Component {
  componentWillUnmount() {
    if (this.props.notifications && this.props.notifications.length > 0) {
      this.props.clearAll();
    }
  }

  render() {
    const { notifications, page, isNavOpen } = this.props;
    const style = {
      Containers: {
        DefaultStyle: {
          position: 'fixed',
          margin: '0 auto',
          top: '0px',
          bottom: 'auto',
          left: isNavOpen && page !== 'landing' ? '200px' : '0px',
          right: '0',
          fontFamily: 'inherit',
          height: 'auto',
          width: '400px',
          padding: '0',
          zIndex: 9998,
          WebkitBoxSizing: 'border-box',
          MozBoxSizing: 'border-box',
          boxSizing: 'border-box',
        },
      },

      NotificationItem: {
        // Override the notification item
        DefaultStyle: {
          // Applied to every notification, regardless of the notification level
          margin: '10px 5px 2px 1px',
        },
      },
    };
    return (
      <div style={{ position: 'relative' }}>
        <NotificationSystem notifications={notifications} style={style} />
      </div>
    );
  }
}

Notification.contextTypes = {
  store: PropTypes.object,
};

Notification.propTypes = {
  notifications: PropTypes.array,
};

function mapDispatchToProps(dispatch) {
  return {
    clearAll: () => {
      dispatch(Notifications.removeAll());
    },
  };
}

export default connect(
  (state) => ({
    notifications: state.notifications,
    isNavOpen: state.auth.isNavOpen,
  }),
  mapDispatchToProps
)(Notification);
