import React, { Component } from 'react';
import styled, { css } from 'styled-components';
import _ from 'lodash';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Spinner from 'common/Spinner';
import ErrorMessage from 'common/Error';
import Papa from 'papaparse';
import PropTypes from 'prop-types';
import { createUser, editUser } from 'redux/modules/duck-admin';
import { baseFont } from 'utils/fonts';
import { mobile } from 'utils/media';
import colors from 'utils/colors';
import { validateEmails as validate, validateEmail } from 'utils/validations';
import LongButton from '../common/buttons/longButton';

const Loader = styled(Spinner)`
  margin: 0 auto;
`;

const FormWrapper = styled.form`
  width: 80%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-self: center;
`;

// TODO: Remove outline none for accessibility
const UploadButton = styled.button`
  width: 100px;
  margin-top: 20px;
  margin-bottom: 50px;
  outline: none;
  border: none;
  margin-left: auto;
`;

const CSVFile = styled.input`
  width: 100%;
  height: 100%;
  opacity: 0;
  z-index: 2;
  outline: none;
  margin-top: -100px;
`;

const FileName = styled.p`
  cursor: pointer;

  position: absolute;
  margin: 0;
  color: ${colors.base_text3};
  font-family: ${baseFont};
  font-size: 14px;
  line-height: 16px;

  text-decoration: underline;
`;

class ManageUserForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fileName: 'Upload CSV',
      genLinkClicked: false,
      firstName: null,
      lastName: null,
      employeeId: null,
      email: null,
      role: '3',
      users: [],
      user: {},
      emailChanged: false, // need this for a certain situation when a user removes an email from an emploee
    };
    this.upload = this.upload.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleOptionChange = this.handleOptionChange.bind(this);
    this.submitUsers = this.submitUsers.bind(this);
    this.submitEditUser = this.submitEditUser.bind(this);
  }

  // Used for radio button
  componentDidMount() {
    switch (this.props.userEdit && this.props.userEdit.user_role) {
      case 3:
        this.setState({ role: '3' });
        break;
      case 2:
        this.setState({ role: '2' });
        break;
      case 1:
        this.setState({ role: '1' });
        break;
      default:
        return;
    }
  }

  handleChange(e) {
    this.setState({ [e.target.name]: e.target.value });
    if (e.target.name === 'email') {
      this.setState({ emailChanged: true });
    }
    e.preventDefault();
  }

  handleOptionChange(e) {
    this.setState({ role: e.target.value });
  }

  submitEditUser(e) {
    const {
      firstName,
      lastName,
      employeeId,
      email,
      role,
      users,
      emailChanged,
    } = this.state;
    const { userEdit } = this.props;
    const editedUser = {
      first_name: firstName ? firstName : userEdit.first_name,
      last_name: lastName ? lastName : userEdit.last_name,
      employee_id: employeeId ? employeeId : userEdit.employee_id,
      email: emailChanged ? email : userEdit.email,
      role: role ? role : userEdit.role,
    };
    this.props.editUser({
      system_id: this.props.selectedSystem.system._id,
      user: editedUser,
      oldEmployeeData: userEdit,
    });
    e.preventDefault();
  }

  submitUsers(e) {
    let result = [];
    const { firstName, lastName, employeeId, email, role, users } = this.state;
    const { userEdit } = this.props;

    const createdUser = {
      first_name: firstName,
      last_name: lastName,
      employee_id: employeeId,
      email: email,
      role: role,
      oldEmployeeData: userEdit,
    };

    if (users && firstName && lastName && employeeId) {
      result = users.concat(createdUser);
    } else if (users.length > 1) {
      result = users;
    } else {
      result = users.concat(createdUser);
    }

    this.props.createUser({
      system_id: this.props.selectedSystem.system._id,
      users: result,
    });
    e.preventDefault();
  }

  upload(evt) {
    const file = evt.target.files[0];
    const reader = new FileReader();

    if (!file) {
      this.setState({
        fileName: 'Upload CSV',
      });
      return;
    }
    reader.readAsText(file);
    reader.onload = (event) => {
      const csvData = event.target.result;
      const data = Papa.parse(csvData, { header: true });

      if (data) {
        // Flatten multi-dimensional arrays and convert to a string.
        const dataString = [].concat(...data.data);
        // .filter((str) => !validateEmail(str));
        this.setState({ fileName: file.name, users: dataString });
      }
    };

    reader.onerror = () => {
      alert(`Unable to read ${file.fileName}`);
    };
  }

  render() {
    const { error, loading, userEdit, currentRole } = this.props;

    return (
      <FormWrapper>
        <label
          style={{
            textAlign: 'left',
            fontSize: 18,
            color: '#083E66',
            marginBottom: 5,
          }}
        >
          First Name
        </label>
        <input
          type="text"
          defaultValue={userEdit ? userEdit.first_name : ''}
          onChange={this.handleChange}
          placeholder="First Name"
          name="firstName"
          style={{ height: 30, width: 450, fontSize: 18, marginBottom: 15 }}
          ref={(input) => (this.input = input)}
        />
        <label
          style={{
            textAlign: 'left',
            fontSize: 18,
            color: '#083E66',
            marginBottom: 5,
          }}
        >
          Last Name
        </label>
        <input
          type="text"
          defaultValue={userEdit ? userEdit.last_name : ''}
          onChange={this.handleChange}
          name="lastName"
          placeholder="Last Name"
          style={{ height: 30, width: 450, fontSize: 18, marginBottom: 15 }}
        />

        <label
          style={{
            textAlign: 'left',
            fontSize: 18,
            color: '#083E66',
            marginBottom: 5,
          }}
        >
          Drop-Off Code
        </label>
        <input
          type="text"
          defaultValue={userEdit ? userEdit.employee_id : ''}
          onChange={this.handleChange}
          name="employeeId"
          // placeholder="Employee Id"
          placeholder="Drop-Off Code"
          style={{ height: 30, width: 450, fontSize: 18, marginBottom: 15 }}
        />
        <label
          style={{
            textAlign: 'left',
            fontSize: 18,
            color: '#083E66',
            marginBottom: 5,
          }}
        >
          {this.state.role
            ? this.state.role === '1' ||
              this.state.role === '2' ||
              (userEdit && userEdit.role === '1') ||
              (userEdit && userEdit.role === '2')
              ? 'Email (Required)'
              : 'Email (Optional)'
            : 'Email (Optional)'}
        </label>
        <input
          type="text"
          defaultValue={userEdit ? userEdit.email : ''}
          onChange={this.handleChange}
          name="email"
          placeholder="Email"
          style={{ height: 30, width: 450, fontSize: 18, marginBottom: 15 }}
        />
        <label
          style={{
            textAlign: 'left',
            fontSize: 18,
            color: '#083E66',
            marginBottom: 5,
          }}
        >
          Role:{' '}
        </label>
        <div
          className="radio"
          style={{
            display: 'flex',
            flexDirection: 'row',
          }}
        >
          <div className="radio">
            <label
              style={{
                fontSize: 16,
                color: 'black',
              }}
            >
              <input
                type="radio"
                value={3}
                checked={this.state.role === '3'}
                onChange={this.handleOptionChange}
              />
              Employee
            </label>
          </div>
          <div
            className="radio"
            style={{
              marginLeft: 50,
              marginBottom: userEdit ? 50 : null,
            }}
          >
            <label
              style={{
                fontSize: 16,
                color: 'black',
              }}
            >
              <input
                type="radio"
                value={2}
                checked={this.state.role === '2'}
                onChange={this.handleOptionChange}
              />
              Admin
            </label>
          </div>
          {currentRole === 1 && (
            <div
              className="radio"
              style={{
                marginLeft: 50,
              }}
            >
              <label
                style={{
                  fontSize: 16,
                  color: 'black',
                }}
              >
                <input
                  type="radio"
                  value={1}
                  checked={this.state.role === '1'}
                  onChange={this.handleOptionChange}
                />
                Owner
              </label>
            </div>
          )}
        </div>
        {!userEdit ? (
          <UploadButton type="button">
            <div style={{ cursor: 'pointer' }}>
              <FileName>{this.state.fileName}</FileName>
              <CSVFile
                type="file"
                name="File Upload"
                id="csvFile"
                onChange={this.upload}
                onClick={(event) => {
                  event.target.value = null;
                }}
                accept=".csv"
              />
            </div>
          </UploadButton>
        ) : null}

        <div
          style={{
            width: '100%',
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          {!userEdit ? (
            <LongButton
              backgroundColor="accent1"
              textColor="white"
              onClick={this.submitUsers}
            >
              {loading ? <Loader /> : 'Create'}
            </LongButton>
          ) : (
            <LongButton
              backgroundColor="accent1"
              textColor="white"
              style={{
                width: '196px',
                height: '44px',
              }}
              onClick={this.submitEditUser}
            >
              {loading ? <Loader /> : 'Save'}
            </LongButton>
          )}
        </div>

        {error && <ErrorMessage>{error}</ErrorMessage>}
      </FormWrapper>
    );
  }
}

ManageUserForm.contextTypes = {
  store: PropTypes.object,
};

function mapStateToProps({ auth, admin, form }) {
  const { owner_systems, selectedSystem, error, loading, profile } = admin;
  return {
    owner_systems,
    selectedSystem,
    error,
    loading,
    profile,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ createUser, editUser }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(ManageUserForm);
