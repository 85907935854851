import React from 'react';
import styled from 'styled-components';

import { baseFont, boldFont } from 'utils/fonts';
import SvgIcon from 'common/icons/SvgIcon';
import colors from 'utils/colors';

import Confirm from 'Admin/ConfirmChangeRole';
import Deactivate from 'Admin/DeactivateUser';
import CancelInvite from 'Admin/CancelInvite';

const Message = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  color: #ffffff;
  font-family: ${baseFont};
  font-size: 15px;
  font-weight: bold;
  line-height: 18px;
  text-align: center;
`;

const Warning = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  min-height: 52px;

  width: auto;
  align-items: center;
  color: #ffffff;
  font-family: ${baseFont};
  font-size: 15px;
  font-weight: bold;
  line-height: 18px;
  text-align: center;
  background: ${colors.action2};
`;

// Notification Options
export const inviteSuccess = {
  // message: 'Your invitation(s) is(are) sent!',
  position: 'tc',
  autoDismiss: 4,
  message: (
    <Message>
      <SvgIcon
        icon="checkmark"
        height="22px"
        width="22px"
        fill={colors.white}
        style={{ marginRight: '15px' }}
        viewBox="0 0 22 22"
      />
      <span>Your invitation(s) is(are) sent!</span>
    </Message>
  ),
};

export const resendVerificationSuccess = (phone) => {
  return {
    // message: 'Your invitation(s) is(are) sent!',
    position: 'tc',
    autoDismiss: 4,
    message: (
      <Message>
        <SvgIcon
          icon="checkmark"
          height="22px"
          width="22px"
          fill={colors.white}
          style={{ marginRight: '15px' }}
          viewBox="0 0 22 22"
        />
        <span>New code sent to {phone}</span>
      </Message>
    ),
  };
};

export const changeRoleNoti = (name, role, email, _access) => {
  let roleText = '';
  if (role == 2) {
    roleText = 'Admin';
  } else {
    roleText = 'User';
  }
  return {
    message: (
      <div>
        {name}
        <br />
        will now have {roleText} access.
        <br />{' '}
        <Confirm name={name} role={role} _access={_access}>
          CONFIRM
        </Confirm>
      </div>
    ),
    position: 'tc',
    autoDismiss: 0,
  };
};

export const noEmailNoti = () => {
  return {
    message: <Warning>Email is required for this role</Warning>,
    position: 'tc',
    autoDismiss: 2,
  };
};

export const removeUserNoti = (name, _access) => {
  return {
    message: (
      <Warning>
        Deactivate {name}?
        <Deactivate name={name} _access={_access}>
          YES
        </Deactivate>
      </Warning>
    ),
    position: 'tc',
    autoDismiss: 0,
  };
};

export const cancelInviteNoti = (email, _user) => {
  return {
    message: (
      <Warning style={{ flexDirection: 'column', padding: '0 10px' }}>
        <p style={{ fontSize: '15px', margin: '5px 0' }}>Are you sure?</p>
        <p style={{ fontSize: '13px', margin: '5px 0' }}>
          Once cancelled this user will be not be able to register for an
          account.{' '}
        </p>
        <CancelInvite _user={_user}>YES</CancelInvite>
      </Warning>
    ),
    position: 'tc',
    autoDismiss: 0,
  };
};

export const emptyValue = {
  message: "Please don't submit empty value!",
  position: 'tc',
  autoDismiss: 4,
};

export const settingsSuccess = {
  message: (
    <Message>
      <SvgIcon
        icon="checkmark"
        height="22px"
        width="22px"
        fill={colors.white}
        style={{ marginRight: '15px' }}
        viewBox="0 0 22 22"
      />
      <span>Changes Updated.</span>
    </Message>
  ),
  position: 'tc',
  autoDismiss: 2,
};

export const removeUserSuccess = (name) => {
  return {
    message: <Message>{name} was removed.</Message>,
    position: 'tc',
    autoDismiss: 2,
  };
};

export const forgotPasswordSuccessMsg = {
  message: 'Your reset password email is sent!',
  position: 'tc',
  autoDismiss: 4,
};

export const forgotPasswordFailMsg = {
  message:
    'This email was not found. Try another email address or contact your adminstrator.',
  position: 'tc',
  autoDismiss: 0,
};

export const passwordSuccessMsg = {
  message: (
    <Message>
      <SvgIcon
        icon="checkmark"
        height="22px"
        width="22px"
        fill={colors.white}
        style={{ marginRight: '15px' }}
        viewBox="0 0 22 22"
      />
      <span>Your password has been changed.</span>
    </Message>
  ),
  position: 'tc',
  autoDismiss: 4,
};

export const tokenExpires = {
  message: 'The Link is invalid or has expired.',
  position: 'tc',
  autoDismiss: 6,
};

export const databaseError = {
  message:
    'Datebase is not response. Please wait couple of minites and try again.',
  position: 'tc',
  autoDismiss: 6,
};

export const sendSMSSuccess = {
  message: 'Text with Pin sent successfully.',
  position: 'tc',
  autoDismiss: 4,
};

export const sendEmailSuccess = {
  message: 'Email with Pin sent successfully.',
  position: 'tc',
  autoDismiss: 4,
};

export const noUser = {
  message: 'Link is invalid or user no longer exists.',
  position: 'tc',
  autoDismiss: 6,
};

export const unsubscribeSuccess = {
  message: 'You have unsubscribed from email notifications.',
  position: 'tc',
  autoDismiss: 4,
};

export const failedEmails = (emails) => {
  const list = emails.join(', ');
  return {
    message: `Following emails already registered for this system: ${list}`,
    position: 'tc',
    autoDismiss: 0,
  };
};

export const addedUsersOnManage = (addedUsers) => {
  return {
    message: `${addedUsers} User(s) added`,
    position: 'tc',
    autoDismiss: 2,
  };
};

export const failedUsersOnManage = (failedUsers) => {
  const listItems = failedUsers.map((userError, index) => {
    return <li key={index}>{userError}</li>;
  });
  return {
    message: (
      <React.Fragment>
        <div style={{ fontSize: '22px' }}>
          {failedUsers.length} users were not added due to the errors below:
        </div>
        <div style={{ textAlign: 'left' }}>{listItems}</div>
      </React.Fragment>
    ),
    position: 'tc',
    autoDismiss: 0,
  };
};

export const failedSecondaryEmails = (emails) => {
  const list = emails.join(', ');
  return {
    message: `Following emails already exist as secondary emails: ${list}`,
    position: 'tc',
    autoDismiss: 0,
  };
};

export const noValuesChanged = {
  message: 'No new changes!',
  position: 'tc',
  autoDismiss: 4,
};

export const acceptInviteFail = {
  message: 'User has already been invited.',
  position: 'tc',
  autoDismiss: 4,
};

export const addCourierSuccessMsg = {
  message: (
    <Message>
      <SvgIcon
        icon="checkmark"
        height="22px"
        width="22px"
        fill={colors.white}
        style={{ marginRight: '15px' }}
        viewBox="0 0 22 22"
      />
      <span>Courier has been added.</span>
    </Message>
  ),
  position: 'tc',
  autoDismiss: 4,
};

export const courierNameExists = {
  message: 'Courier already exists.',
  position: 'tc',
  autoDismiss: 0,
};

export const courierCodeExists = {
  message: 'This code has already been assigned to another courier.',
  position: 'tc',
  autoDismiss: 4,
};

export const invalidCourierCode = {
  message: 'Courier code must be 6 digits.',
  position: 'tc',
  autoDismiss: 4,
};

export const emptyCourier = {
  message: 'Name and Code cannot be empty.',
  position: 'tc',
  autoDismiss: 4,
};

export const vacationHoldReq = {
  message: 'Request has been submitted successfully.',
  position: 'tc',
  autoDismiss: 4,
};

export const vacationHoldReqFail = {
  message: 'Both the first day and the last day of your vacation are required.',
  position: 'tc',
  autoDismiss: 4,
};

export const vacationHoldExceedLimit = {
  message: 'Vacation duration exceeds the maximum days of the system setting.',
  position: 'tc',
  autoDismiss: 4,
};

export const vacationHoldDecision = {
  message: 'User Request Completed',
  position: 'tc',
  autoDismiss: 4,
};

export const removeCourier = (system, name, code) => {
  return {
    message: (
      <Message>
        <SvgIcon
          icon="checkmark"
          height="22px"
          width="22px"
          fill={colors.white}
          style={{ marginRight: '15px' }}
          viewBox="0 0 22 22"
        />
        <span>Courier has been removed.</span>
      </Message>
    ),
    position: 'tc',
    autoDismiss: 10,
  };
};

export const uploadSuccess = {
  message: 'Your logo has been uploaded!',
  position: 'tc',
  autoDismiss: 4,
};

export const uploadFail = {
  message: 'Maximum file upload size is 2MB.',
  position: 'tc',
  autoDismiss: 0,
};

export const noFile = {
  message: 'No file found!',
  position: 'tc',
  autoDismiss: 0,
};

export const maxSizeExceeded = {
  message: 'File height exceeding 400px.',
  position: 'tc',
  autoDismiss: 0,
};

export const emptyPhone = {
  message: 'You must provide a phone number.',
  position: 'tc',
  autoDismiss: 4,
};

export const invalidPhone = {
  message: 'You must provide a validate phone number.',
  position: 'tc',
  autoDismiss: 4,
};

export const emptyTimezone = {
  message: 'You must fill a Time Zone.',
  position: 'tc',
  autoDismiss: 4,
};

export const loginError = (error) => {
  return {
    message: error,
    position: 'tc',
    autoDismiss: 4,
  };
};

export const verifyUserSuccess = {
  message: (
    <Message>
      <SvgIcon
        icon="checkmark"
        height="22px"
        width="22px"
        fill={colors.white}
        style={{ marginRight: '15px' }}
        viewBox="0 0 22 22"
      />
      <span>Your account has been verified.</span>
    </Message>
  ),
  position: 'tc',
  autoDismiss: 4,
};

export const verifyUserFail = {
  message: 'Invalid Code',
  position: 'tc',
  autoDismiss: 4,
};

export const setPinNotiSuccess = {
  message: (
    <Message>
      <SvgIcon
        icon="checkmark"
        height="22px"
        width="22px"
        fill={colors.white}
        style={{ marginRight: '15px' }}
        viewBox="0 0 22 22"
      />
      <span>Your pin is set.</span>
    </Message>
  ),
  position: 'tc',
  autoDismiss: 4,
};
