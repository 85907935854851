import React, { Component } from 'react';
import styled, { css } from 'styled-components';

import ReactModal from 'react-modal';
import ReactTable from 'react-table';
import 'react-table/react-table.css';
import moment from 'moment';

import LoaderPage from 'common/LoaderPage';
import SvgIcon from 'common/icons/SvgIcon';

import colors from 'utils/colors';
import { baseFont, boldFont } from 'utils/fonts';
import { mobile } from 'utils/media';
import GetTableSize from 'utils/getTableSize';

const Wrapper = styled.div`
  width: 100%;
  max-height: 100%;
  box-sizing: border-box;
  overflow-y: auto;
  ::-webkit-scrollbar {
    width: 6px;
  }
  ::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(4, 96, 132, 0.5);
  }
  ::-webkit-scrollbar-thumb {
    -webkit-box-shadow: inset 0 0 6px rgba(4, 96, 132, 1);
  }
  ::-webkit-scrollbar-thumb:hover {
    background: ${colors.primary_blue};
  }

  ${mobile(
    css`
      font-size: 14px;
    `
  )};
`;

const NA = styled.div`
  min-height: 100px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  font-size: 30px;
  color: ${colors.primary_blue};
  font-family: ${boldFont};
`;

const LabelButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 24px;
  width: 52px;
  border: 1px solid ${colors.base_text3};
  border-radius: 2px;
  background: transparent;
  cursor: pointer;
  ${(p) =>
    !p.exists
      ? `
    border: 1px solid #DDDDDD;
    cursor: default;
    `
      : null}
`;

const LabelWrapper = styled.div`
  width: 100%;
  height: 100%;
  margin: auto;
  font-family: ${boldFont};
  font-weight: bolder;
  display: flex;
  flex-direction: column;
  justify-content: center;
  transition: all 300ms ease-in-out;
`;

const PaginationButton = styled.button`
  color: ${colors.base_text3};
  font-family: ${baseFont};
  font-size: 14px;
  letter-spacing: 0.71px;
  line-height: 18px;
  border: none;
`;

const NoData = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  height: 540px;
  background-color: #ffffff;
`;

const NoPackages = styled.span`
  color: ${colors.base_text3};
  font-family: ${baseFont};
  font-size: 24px;
  font-weight: bold;
  letter-spacing: -0.4px;
  line-height: 29px;
`;

class SystemLogTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showCameraModal: false,
      label_url: null,
      search: '',
    };
    this.handleFilter = this.handleFilter.bind(this);
    this.toggleCameraModal = this.toggleCameraModal.bind(this);
    this.showLabel = this.showLabel.bind(this);
  }

  componentWillMount() {
    // if own user parcels or OWNER can see other user parcels labels
    ReactModal.setAppElement('body');
  }

  handleFilter(e) {
    const input_v = e.currentTarget.value.toLowerCase();
    this.setState({ search: input_v });
  }

  toggleCameraModal() {
    this.setState({ showCameraModal: !this.state.showCameraModal });
  }

  showLabel(label_url) {
    this.setState({ showCameraModal: !this.state.showCameraModal, label_url });
  }

  renderLabelImg(label_url) {
    return (
      <img
        style={{
          width: '100%',
          height: '100%',
        }}
        src={label_url}
        alt="label"
        onError={(e) => {
          this.setState({ label_url: null });
        }}
      />
    );
  }

  render() {
    const { parcels, log_loading } = this.props;
    const { pageStart, pageSizeArray } = GetTableSize(parcels.length);
    const columns = [
      {
        Header: 'Delivered',
        headerClassName: 'table-header',
        className: 'table-cell table-col-timestamp',
        accessor: 'created_at',
        width: 200,
        sortMethod: (a, b) => moment(a).diff(moment(b)),
        Cell: ({ row }) => {
          if (row.created_at) {
            const created_at = moment(row.created_at).format(
              'MM/DD/YYYY | h:mm a'
            );
            return created_at;
          }
        },
      },
      {
        Header: 'Status',
        headerClassName: 'table-header',
        className: 'table-cell',
        accessor: 'status',
        Cell: ({ row }) => (
          <p
            style={{
              margin: 0,
              color:
                row.status === 'Expiring Soon!'
                  ? colors.warning_red
                  : 'inherit',
            }}
          >
            {row.status}
          </p>
        ),
      },
      {
        // Header: 'Order Number',
        Header: 'Parcel ID',
        headerClassName: 'table-header',
        className: 'table-cell',
        accessor: 'pin',
      },
      // This is commented out for the Host Project since this isn't used in it. This is still being used for retail.
      // {
      //   Header: 'Employee ID',
      //   headerClassName: 'table-header',
      //   className: 'table-cell',
      //   accessor: '_user.employee_id',
      // },
      {
        Header: 'Admin',
        headerClassName: 'table-header',
        className: 'table-cell',

        accessor: '_user',
        sortMethod: (a, b, desc) => {
          if (desc) {
            if (a && !b) {
              return 1;
            }

            if (!a && b) {
              return -1;
            }

            if (!a && !b) {
              return 0;
            }

            if (a && b && a[0] > b[0]) {
              return 1;
            }

            if (a && b && a[0] < b[0]) {
              return -1;
            }

            return 0;
          } else {
            if (a && !b) {
              return -1;
            }

            if (!a && b) {
              return 1;
            }

            if (!a && !b) {
              return 0;
            }

            if (a && b && a[0] > b[0]) {
              return 1;
            }

            if (a && b && a[0] < b[0]) {
              return -1;
            }

            return 0;
          }
        },
        Cell: ({ row }) => {
          if (row._user.first_name && row._user.last_name) {
            const fullName = `${row._user.first_name} ${row._user.last_name} `;
            return fullName;
          } else {
            return 'N/A';
          }
        },
      },
      {
        Header: 'Picked Up Date',
        headerClassName: 'table-header',
        className: 'table-cell table-col-timestamp',
        accessor: 'picked_up_at',
        minWidth: 115,
        sortMethod: (a, b) => moment(a).diff(moment(b)),
        Cell: ({ row }) => {
          if (row.picked_up_at) {
            const picked_up_at = moment(row.picked_up_at).format(
              'MM/DD/YYYY | h:mm a'
            );
            return picked_up_at;
          } else {
            return 'N/A';
          }
        },
      },

      // Taken out for the Host Project
      // {
      //   Header: 'Signature',
      //   headerClassName: 'table-header nounderline',
      //   accessor: 'label_url',
      //   className: 'table-cell label-column',
      //   style: { display: 'flex', justifyContent: 'center' },
      //   sortable: false,
      //   Cell: ({ row }) => {
      //     return (
      //       <LabelButton
      //         onClick={
      //           row.label_url ? () => this.showLabel(row.label_url) : null
      //         }
      //         exists={row.label_url}
      //       >
      //         <SvgIcon
      //           icon="camera"
      //           viewBox="0 0 13 11"
      //           height="12px"
      //           width="14px"
      //           fill={row.label_url ? colors.base_text3 : colors.base1}
      //         />
      //       </LabelButton>
      //     );
      //   },
      // },
    ];
    return !log_loading && parcels ? (
      <Wrapper style={{ width: parcels.length === 0 && 'auto' }}>
        <ReactTable
          data={parcels}
          style={{ minWidth: parcels.length === 0 && 'auto' }}
          columns={columns}
          noDataText=""
          className="table -highlight"
          defaultPageSize={pageStart}
          pageSizeOptions={pageSizeArray}
          defaultSortDesc={true}
          minRows={0}
          defaultSorted={[
            {
              id: 'created_at',
              desc: true,
            },
          ]}
          rowsText={''}
          showPagination={parcels.length > 0}
          NoDataComponent={() => (
            <NoData>
              <SvgIcon
                icon="package"
                width="50px"
                height="50px"
                viewBox="0 0 59 59"
              />
              <br />
              <NoPackages>No Packages Yet</NoPackages>
            </NoData>
          )}
          PreviousComponent={(props) => {
            return (
              <div>
                <PaginationButton
                  {...props}
                  style={{
                    color: props.disabled ? colors.accent4 : colors.base_text3,
                  }}
                >
                  PREV
                </PaginationButton>
              </div>
            );
          }}
          NextComponent={(props) => {
            return (
              <PaginationButton
                {...props}
                style={{
                  color: props.disabled ? colors.accent4 : colors.base_text3,
                }}
              >
                NEXT
              </PaginationButton>
            );
          }}
        />
        <ReactModal
          className="Modal"
          overlayClassName="Overlay"
          closeTimeoutMS={150}
          isOpen={this.state.showCameraModal}
          onRequestClose={this.toggleCameraModal}
          shouldCloseOnOverlayClick
          shouldCloseOnEsc
        >
          <LabelWrapper>
            {this.state.label_url ? (
              this.renderLabelImg(this.state.label_url)
            ) : (
              <h2>This parcel does not have a label picture on file.</h2>
            )}
          </LabelWrapper>
        </ReactModal>
      </Wrapper>
    ) : (
      <LoaderPage color="accent1" />
    );
  }
}

export default SystemLogTable;
