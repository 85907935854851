import React, { Component } from 'react';
import styled, { css } from 'styled-components';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { vacationHoldRequest, fetchUser } from 'redux/modules/duck-auth';
import { reduxForm, Field } from 'redux-form';
import _ from 'lodash';
import moment, { relativeTimeThreshold } from 'moment';
import renderSystemSelect from 'common/forms/renderSystemSelect';
import {
  fetchAnalytics,
  fetchVacationRequests,
  updateVacationHold,
  fetchCounters,
} from 'redux/modules/duck-admin';
import SvgIcon from 'common/icons/SvgIcon';
import colors from 'utils/colors';
import { baseFont } from 'utils/fonts';
import Spinner from 'common/Spinner';
import DayPicker, { DateUtils } from 'react-day-picker';
import { phone } from 'utils/media';
import LongButton from 'common/buttons/longButton';
import 'react-day-picker/lib/style.css';

const Loader = styled(Spinner)`
  margin: 0 auto;
`;

const FormWrapper = styled.form`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const MainWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const Instruction = styled.div`
  width: 60%;
  padding: 20px;
  font-weight: 900;
  margin-top: -15px;
  margin: 0 auto;
  color: #8b9898;
  border: 1px solid ${colors.background_gray};
  border-radius: 3px;
  text-align: center;
`;

const NAText = styled.span`
  color: ${colors.secondary_blue};
  display: 'inline-block';
`;

const IconWrapper = styled.div`
  display: inline-block;
  margin-top: 20px;
`;

const VacationLimit = styled.div`
  margin: -10px 0 15px 0;
  color: ${colors.action3};
`;

const VacationOff = styled(VacationLimit)`
  color: ${colors.warning_red};
`;

const LongButtonDisabled = styled(LongButton)`
  pointer-events: none;
`;

const Na = styled.div`
  height: 27px;
`;

const DayPickerWrapper = styled.div`
  outline: none;
`;

const Info = styled.p`
  color: ${colors.secondary_blue};
  margin-bottom: 20px;
  font-weight: bold;
  height: 18px;
`;

const DataCards = styled.div`
  display: grid;
  grid-template-columns: 300px 450px;
  grid-gap: 5px;
  justify-content: center;
  position: relative;
  left: 85px;
  ${'' /* change to column layout on mobile */}
  @media (max-width: 860px) {
    display: flex;
    flex-flow: column;
    margin: auto;
    position: unset;
  }
`;

const NATitle = styled.h3`
  display: inline-block;
  margin: auto;
  width: 100vw;
  box-sizing: border-box;
  justify-content: space-around;
`;

const NAWrapper = styled.div`
  display: block;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 200px;
`;

const NASub = styled.div`
  font-size: 16px;
  font-family: 'Swiss721BTBold';
  color: ${colors.secondary_blue};
  display: inline-block;
  position: relative;
  left: 50px;
`;

const SelectWrapper = styled.div`
  display: block;
  width: 100%;
`;

const SelectWrap = styled.div`
  position: relative;
  :after {
    content: '';
    position: absolute;
    top: 50%;
    right: 8px;
    margin-top: -3px;
    border-width: 6px;
    border-style: solid;
    border-color: ${colors.base_text3} transparent transparent;
  }
`;

const HorizontalRuler = styled.hr`
  min-width: 500px;
`;

const SelectField = styled.select`
  width: 100%;
  height: 25px;
  font-family: ${baseFont};
  font-size: 14px;
  font-weight: bold;
  color: ${colors.base_text3};
  background: white;
  border-radius: 3px;
  border-color: ${colors.base_text3};
  letter-spacing: 2px;
  text-transform: uppercase;
  line-height: 16px;
`;

const VacationCards = styled.span`
  margin: 20px;
`;

const ResetButton = styled.button`
  height: 30px;
  width: 60px;
  margin-left: 10px;
  font-size: 16px;
  border: 1px solid white;
  padding: 0px;
  font-family: ${baseFont};
  text-align: center;
  font-weight: 500;
  letter-spacing: 0px;
  ${phone(css`
    width: 100%;
  `)};
  background-color: ${colors.secondary_blue};
  color: white;
  cursor: pointer;
  outline: none;
  border-radius: 3px;
  border-color: transparent;
  transition: background-color 0.1s ease-in-out;
  svg {
    & .path {
      stroke: white;
    }
  }
  &:hover {
    background-color: white;
    color: ${colors.secondary_blue};
    border-color: ${colors.secondary_blue};
    svg {
      & .path {
        stroke: ${colors.secondary_blue};
      }
    }
  }
`;

const DeclineButton = styled.button`
  width: 50px;
  font-size: 12px;
  margin: 0 15px;
  height: 20px;
  border: none;
  color: red;
`;

class VacationHoldRequest extends Component {
  static defaultProps = {
    numberOfMonths: 2,
  };

  constructor(props) {
    super(props);

    this.state = {
      showModal: false,
      targetUserEmail: '',
      vacationLimit: null,
      currentRequestIdx: 0,
      grabDirectory: false,
      buttonPressed: false,
    };

    this.handleDayClick = this.handleDayClick.bind(this);
    this.handleResetClick = this.handleResetClick.bind(this);
    this.handleSystemChange = this.handleSystemChange.bind(this);
    this.state = this.getInitialState();
  }

  getInitialState() {
    return {
      from: undefined,
      to: undefined,
      showModal: false,
      targetUserEmail: '',
      vacationLimit: null,
      currentRequestIdx: 0,
      grabDirectory: false,
      buttonPressed: false,
    };
  }

  handleDayClick(day, modifiers = {}) {
    if (modifiers.disabled) {
      return;
    }
    const range = DateUtils.addDayToRange(day, this.state);
    this.setState(range);
  }

  handleResetClick() {
    this.setState(this.getInitialState());
  }

  componentDidMount() {
    if (this.props.directory[0]) {
      this.props.fetchAnalytics(
        this.props.directory[0]._id,
        this.props.directory[0].timezone,
        this.props.history
      );
      this.props.fetchVacationRequests(
        this.props.directory[0]._id,
        this.props.history
      );
    }
  }

  handleSystemChange(e) {
    this.setState({
      selectedSystem: this.props.directory[e.target.selectedIndex],
    });
    this.setState({
      vacationLimit: this.props.directory[e.target.selectedIndex]
        .vacation_limit,
    });

    if (localStorage.user_token) {
      this.props.fetchAnalytics(
        this.props.directory[e.target.selectedIndex]._id,
        this.props.directory[e.target.selectedIndex].timezone,
        this.props.history
      );

      this.props.fetchVacationRequests(
        this.props.directory[e.target.selectedIndex]._id,
        this.props.history
      );
    } else {
      this.props.fetchAnalytics(
        this.props.directory[0]._id,
        this.props.directory[0].timezone,
        this.props.history
      );

      this.props.fetchVacationRequests(
        this.props.directory[0]._id,
        this.props.history
      );
    }
  }

  renderVacationRequest() {
    if (this.props.vacations) {
      const { vacations, analytics_loading } = this.props;
      if (vacations.length > 0) {
        return (
          <div>
            {_.map(vacations, (vacation, index) => {
              if (vacations[index].email === this.props.user.email) {
                const timestamp = `${moment(vacation.hold_from).format(
                  'MM/DD/YYYY'
                )} - ${moment(vacation.hold_to).format('MM/DD/YYYY')}`;
                return (
                  <div
                    key={vacation._id}
                    style={{
                      width: '100%',
                      display: 'inline-block',
                      flexDirection: 'row',
                      fontFamily: 'Swiss721BTBold',
                    }}
                  >
                    <div
                      style={{
                        fontSize: '18px',
                        lineHeight: '21px',
                        color: colors.base_text3,
                      }}
                    />
                    <div
                      style={{
                        fontSize: '14px',
                        lineHeight: '21px',
                        fontWeight: 'bold',
                        color: colors.base_text2,
                        width: '250px',
                        left: '50px',
                        position: 'relative',
                        margin: '3px',
                      }}
                    >
                      {timestamp}
                      <DeclineButton
                        onClick={async () => {
                          const idx = index;
                          this.setState({ buttonPressed: true });
                          await this.props.updateVacationHold(
                            idx,
                            'declined',
                            vacation._system,
                            this.props.vacations,
                            this.props.history
                          );
                        }}
                      >
                        X
                      </DeclineButton>
                    </div>
                  </div>
                );
              }
            })}
          </div>
        );
      } else {
        return <NASub>None Requested Yet</NASub>;
      }
    }
  }

  renderAnalytics() {
    if (this.props.analytics) {
      const { approvedVacations } = this.props.analytics;
      if (approvedVacations['thismonth'].length > 0) {
        return (
          <div>
            {_.map(approvedVacations['thismonth'], (vacation, index) => {
              if (
                approvedVacations['thismonth'][index].email ===
                this.props.user.email
              ) {
                const timestamp = `${moment(vacation.hold_from).format(
                  'MM/DD/YYYY'
                )} - ${moment(vacation.hold_to).format('MM/DD/YYYY')}`;
                return (
                  <div
                    key={vacation._id}
                    style={{
                      width: '100%',
                      display: 'flex',
                      flexDirection: 'row',
                      fontFamily: 'Swiss721BTBold',
                    }}
                  >
                    <div
                      style={{
                        fontSize: '18px',
                        lineHeight: '21px',
                        color: colors.base_text3,
                      }}
                    />
                    <div
                      style={{
                        fontSize: '14px',
                        lineHeight: '21px',
                        fontWeight: 'bold',
                        position: 'relative',
                        left: '50px',
                        color: colors.base_text2,
                        margin: '3px',
                      }}
                    >
                      {timestamp}
                    </div>
                  </div>
                );
              }
            })}
          </div>
        );
      }
    } else {
      return <NASub>None Scheduled Yet</NASub>;
    }
  }

  render() {
    const { directory, loading, vacationHoldRequest } = this.props;
    const { from, to } = this.state;
    //
    const modifiers = { start: from, end: to };

    return (
      <MainWrapper>
        <DataCards>
          <div>
            <VacationCards>
              <IconWrapper>
                <SvgIcon
                  icon="holdRequestSmallSpecial"
                  style={{
                    marginTop: '10px',
                    position: 'relative',
                    top: '7px',
                    color: '#5c7fa7',
                  }}
                  height="25px"
                  width="25px"
                  viewBox="0 0 36 36"
                />
              </IconWrapper>
              <NAText>Approved Requests:</NAText>
            </VacationCards>

            <NAWrapper>{this.renderAnalytics()}</NAWrapper>
          </div>
          <div>
            <VacationCards>
              <IconWrapper>
                <SvgIcon
                  icon="holdRequestSmallSpecial"
                  style={{
                    marginTop: '10px',
                    position: 'relative',
                    top: '7px',
                    color: '#5c7fa7',
                  }}
                  height="25px"
                  width="25px"
                  viewBox="0 0 36 36"
                />
              </IconWrapper>
              <NAText>Current Requests: </NAText>
              <NAWrapper>{this.renderVacationRequest()}</NAWrapper>
            </VacationCards>
          </div>
        </DataCards>
        <HorizontalRuler />
        <FormWrapper
          onSubmit={this.props.handleSubmit(() => {
            let selectedSystemId;

            if (directory.length > 0 && this.state.selectedSystem) {
              selectedSystemId = this.state.selectedSystem._id;
            } else if (directory.length > 0) {
              selectedSystemId = directory[0]._id;
            }
            vacationHoldRequest(
              selectedSystemId,
              this.state.from,
              this.state.to,
              this.props.history
            );
          })}
        >
          <DayPickerWrapper
            className="RangeExample"
            style={{ display: 'flex', justifyContent: 'center' }}
          >
            <DayPicker
              className="Selectable"
              numberOfMonths={this.props.numberOfMonths}
              selectedDays={[from, { from, to }]}
              modifiers={modifiers}
              onDayClick={this.handleDayClick}
              fromMonth={new Date()}
              disabledDays={[{ before: new Date() }]}
            />
          </DayPickerWrapper>
          <Info>
            {!from && !to && 'Please Select the First Day.'}
            {from && !to && 'Please Select the Last Day.'}
            {from &&
              to &&
              `Selected from ${from.toLocaleDateString()} to
          ${to.toLocaleDateString()}`}{' '}
            {from && to && (
              <ResetButton className="link" onClick={this.handleResetClick}>
                Reset
              </ResetButton>
            )}
          </Info>
          <Field
            style={{ marginTop: '0px' }}
            name="system_id"
            label="System"
            component={renderSystemSelect}
            systems={directory}
            onChange={this.handleSystemChange}
          />
          {directory.length > 0 ? (
            (directory[0].vacation_limit === '0' &&
              this.state.vacationLimit === undefined) ||
            this.state.vacationLimit === '0' ? (
              <VacationOff>
                Hold Request is not available for this system. Please contact
                your system manager with questions.
              </VacationOff>
            ) : (
              <VacationLimit>
                Maximum Hold Request:{' '}
                {this.state.vacationLimit
                  ? this.state.vacationLimit
                  : this.props.directory[0].vacation_limit}{' '}
                Days
              </VacationLimit>
            )
          ) : (
            <Na />
          )}

          {directory.length === 0 ||
          (directory[0].vacation_limit === '0' &&
            this.state.vacationLimit === undefined) ||
          this.state.vacationLimit === '0' ? (
            <LongButtonDisabled
              textColor="white"
              backgroundColor="primary_gray"
              type="submit"
              disabled={true}
              style={{ overflow: 'hidden' }}
            >
              {loading ? <Loader /> : 'SUBMIT'}
            </LongButtonDisabled>
          ) : (
            <LongButton
              textColor="white"
              backgroundColor="accent1"
              type="submit"
            >
              {loading ? <Loader /> : 'SUBMIT'}
            </LongButton>
          )}
        </FormWrapper>
        <Instruction>
          A Hold Request can be requested from management when you will be
          unable to retrieve packages from your locker system for an extended
          period of time. An approved Hold Request will allow all of your
          packages to remain safely in the locker system for the duration of
          your absence. Packages will not expire until the approved end date of
          the Hold Request.
        </Instruction>
      </MainWrapper>
    );
  }
}

function mapStateToProps({ auth, form, admin }) {
  let initialValues = null;
  const { user, directory, loading } = auth;
  const { vacationHoldForm } = form;
  const {
    systems,
    selectedSystem,
    analytics,
    analytics_loading,
    vacations,
  } = admin;
  if (user) {
    initialValues = {
      first_name: user.first_name,
      last_name: user.last_name,
      email: user.email,
      secondary_email: user.secondary_email || '',
      phone: user.phone,
    };
  }
  return {
    directory,
    loading,
    vacationHoldForm,
    systems,
    selectedSystem,
    analytics,
    analytics_loading,
    user,
    initialValues,
    vacations,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      vacationHoldRequest,
      fetchAnalytics,
      fetchUser,
      fetchVacationRequests,
      updateVacationHold,
      fetchCounters,
    },
    dispatch
  );
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  reduxForm({
    form: 'vacationHoldForm',
    touchOnBlur: false,
  })(VacationHoldRequest)
);
